import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProgressBar from "../../../shared/ProgressBar/ProgressBar";
import "./score.style.scss";
import BackImg from "../../../assets/img/Back.png";
import smileImg from "../../../assets/img/simleFace.png";
import sadImg from "../../../assets/img/sadFace.png";
import LineChart from "../../../shared/LineChart/LineChart";
import { SCORE_CARD, SCORE_CARD_ALL } from "../../../services/EndPoints";
import { HttpCallGet } from "../../../services/NetworkCall";
import Spinner from "../../../shared/Spinner/Spinner";
import axios from "axios";
import { formatDate } from "../../../util/util";

const ScoreChild = () => {
  const [graph, setGraph] = useState<any>([]);
  const [labels, setLabels] = useState<any>([]);
  const [graphName, setGraphName] = useState("");
  const { itemId } = useParams();
  const [userData, setuserData] = useState<any>();
  const [scoreCardAllData, setScoreCardAllData] = useState<any>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [epigenticAge, setEpigenticAge] = useState<any>();
  const [factor,setFactor] =useState<any>([]);


  const factors = (a = 0, graphName: string) => {
    setGraph(scoreCardAllData[graphName].value);
    setLabels(scoreCardAllData[graphName].date);
    CheckSelectedProgressByIndex(a);
    navigate(`/scoreboard/${a}`);
  };

  /** Check are we getting any value with query based [index] on that we set our graph name  */

  const CheckSelectedProgressByIndex = (id: number) => {
    switch (id) {
      case 0:
        setGraphName(`Cardiovascular System`);
        return `cardiovascularSystem`;
      case 1:
        setGraphName(`Hormones & Metabolism`);
        return `hormonesMetabolism`;
      case 2:
        setGraphName(`Respiratory System`);
        return `respiratorySystem`;
      case 3:
        setGraphName(`Epigenetic Age`);
        return `epigeneticAge`;
      case 4:
        setGraphName(`Hupe Health Score`);
        return `hupeHealthScore`;
      case 5:
        setGraphName(`Energy / Mitochondrial`);
        return `energyMitochondria`;
      case 6:
        setGraphName(`Kidney`);
        return `kidney`;
      case 7:
        setGraphName(`Longevity / Inflammation`);
        return `longevityInflammation`;
    }
  };
  const getScoreBoardDetails = () => {
    setLoading(true);
    HttpCallGet(SCORE_CARD, sessionStorage.getItem("AUTH_TOKEN"))
      .then((response: any) => {
        setLoading(false);
        if (response.data.status === 200) {
          setuserData(response.data.data);
          DobToAgeConverter(response.data.data.dateOfBirth);
          getScoreCardAllDetails();
          getPositiveAndNegativeFactor();
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

   /**
   *
   * @param dateString
   * @returns ages  and @setEpigenticAge
   */

   const DobToAgeConverter = (dateString: any) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setEpigenticAge(age);
    return age;
  };


  const scoreCardGraphHandler = (scoreCardData: any) => {
    const graphFinalData = {
      cardiovascularSystem: {
        date: [],
        value: [],
      },
      energyMitochondria: {
        date: [],
        value: [],
      },
      epigeneticAge: {
        date: [],
        value: [],
      },
      hormonesMetabolism: {
        date: [],
        value: [],
      },
      hupeHealthScore: {
        date: [],
        value: [],
      },
      kidney: {
        date: [],
        value: [],
      },
      longevityInflammation: {
        date: [],
        value: [],
      },
      respiratorySystem: {
        date: [],
        value: [],
      },
    };

    scoreCardData.forEach((element: any) => {
      const cardio: any = graphFinalData["cardiovascularSystem"];
      cardio.date.push(formatDate(element.reportDate));
      cardio.value.push(element.cardiovascularSystem);

      const energy: any = graphFinalData["energyMitochondria"];
      energy.date.push(formatDate(element.reportDate));
      energy.value.push(element.energyMitochondria);

      const epic: any = graphFinalData["epigeneticAge"];
      epic.date.push(formatDate(element.reportDate));
      epic.value.push(element.epigeneticAge);

      const hormones: any = graphFinalData["hormonesMetabolism"];
      hormones.date.push(formatDate(element.reportDate));
      hormones.value.push(element.hormonesMetabolism);

      const health: any = graphFinalData["hupeHealthScore"];
      health.date.push(formatDate(element.reportDate));
      health.value.push(element.hupeHealthScore);

      const kidney: any = graphFinalData["kidney"];
      kidney.date.push(formatDate(element.reportDate));
      kidney.value.push(element.kidney);

      const longevity: any = graphFinalData["longevityInflammation"];
      longevity.date.push(formatDate(element.reportDate));
      longevity.value.push(element.longevityInflammation);

      const respiratory: any = graphFinalData["respiratorySystem"];
      respiratory.date.push(formatDate(element.reportDate));
      respiratory.value.push(element.respiratorySystem);
    });

    return graphFinalData;
  };

  const getScoreCardAllDetails = () => {
    setLoading(true);
    HttpCallGet(SCORE_CARD_ALL, sessionStorage.getItem("AUTH_TOKEN"))
      .then((response: any) => {
        setLoading(false);
        const scoreDataArray: any = scoreCardGraphHandler(response.data.data);
        setScoreCardAllData(scoreDataArray);
        const graphNameCallback: any = CheckSelectedProgressByIndex(Number(itemId));
        setGraph(scoreDataArray[graphNameCallback].value);
        setLabels(scoreDataArray[graphNameCallback].date);

      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };


  const getPositiveAndNegativeFactor = () => {
    setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/get/scoreCardFactor?scoreCardId=${sessionStorage.getItem("scoreCardId")}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
            },
          }
        )
        .then((response: any) => {
          setLoading(false);
          setFactor(response.data.data);
        })
        .catch((err: any) => {
          setLoading(false);
          console.log(err);
        });
    
  };

  useEffect(() => {
    getScoreBoardDetails();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : userData ? (
        <div className="MainPannel bgclr space cordsySec ScoreChild">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h3 className="heading3">
                  <Link to="/">
                    <img src={BackImg} className="arrow" />
                  </Link>
                  {`${graphName ? graphName : `graph name not found`}`}
                </h3>
              </div>
            </div>
            <div className="scorecard-section">
              <div
                className="cardio-system"
                onClick={() => factors(4, "hupeHealthScore")}
              >
                <a
                  className={`component-section1 ${
                    graphName == "Hupe Health Score" ? `isActive` : ``
                  }`}
                >
                  <div className="imgDiv">
                    <ProgressBar
                      percentage={Math.round(userData["hupeHealthScore"]) || 0}
                      size={"80"}
                    />
                  </div>
                  <h6 className="component-heading1">Hupe Health Score</h6>
                </a>
              </div>
              <div
                className="cardio-system"
                onClick={() => factors(3, "epigeneticAge")}
              >
                <a
                  className={`component-section1 ${
                    graphName == "Epigentice Age" ? `isActive` : ``
                  } `}
                >
                  <div className="imgDiv">
                    <ProgressBar
                      percentage={Math.round(userData["epigeneticAge"]) || 0}
                      checkAge={
                        userData["epigeneticAge"]  <= epigenticAge
                        ? "#27AE60"
                        : "#EB5757"
                      }
                      size={"80"}
                    />
                  </div>
                  <h6 className="component-heading1">Epigentice Age</h6>
                </a>
              </div>

              <div
                className="cardio-system"
                onClick={() => factors(0, "cardiovascularSystem")}
              >
                <a
                  className={`component-section1 ${
                    graphName == "Cardiovascular System" ? `isActive` : ``
                  }  `}
                >
                  <div className="imgDiv">
                    <ProgressBar
                      percentage={
                        Math.round(userData["cardiovascularSystem"]) || 0
                      }
                      size={"80"}
                    />
                  </div>
                  <h6 className="component-heading1">Cardiovascular System</h6>
                </a>
              </div>

              <div
                className="cardio-system"
                onClick={() => factors(1, "hormonesMetabolism")}
              >
                <a
                  className={`component-section1 ${
                    graphName == "Hormones & Metabolism" ? `isActive` : ``
                  }  `}
                >
                  <div className="imgDiv">
                    <ProgressBar
                      percentage={
                        Math.round(userData["hormonesMetabolism"]) || 0
                      }
                      size={"80"}
                    />
                  </div>
                  <h6 className="component-heading1">Hormones & Metabolism</h6>
                </a>
              </div>

              <div
                className="cardio-system"
                onClick={() => factors(2, "respiratorySystem")}
              >
                <a
                  className={`component-section1 ${
                    graphName == "Respiratory System" ? `isActive` : ``
                  }`}
                >
                  <div className="imgDiv">
                    <ProgressBar
                      percentage={
                        Math.round(userData["respiratorySystem"]) || 0
                      }
                      size={"80"}
                    />
                  </div>
                  <h6 className="component-heading1">Respiratory System</h6>
                </a>
              </div>

              <div
                className="cardio-system"
                onClick={() => factors(5, "energyMitochondria")}
              >
                <a
                  className={`component-section1 ${
                    graphName == "Energy / Mitochondrial" ? `isActive` : ``
                  } `}
                >
                  <div className="imgDiv">
                    <ProgressBar
                      percentage={
                        Math.round(userData["energyMitochondria"]) || 0
                      }
                      size={"80"}
                    />
                  </div>
                  <h6 className="component-heading1">Energy / Mitochondrial</h6>
                </a>
              </div>

              <div
                className="cardio-system"
                onClick={() => factors(6, "kidney")}
              >
                <a
                  className={`component-section1 ${
                    graphName == "Kidney" ? `isActive` : ``
                  }`}
                >
                  <div className="imgDiv">
                    <ProgressBar
                      percentage={Math.round(userData["kidney"]) || 0}
                      size={"80"}
                    />
                  </div>
                  <h6 className="component-heading1">Kidney</h6>
                </a>
              </div>

              <div
                className="cardio-system"
                onClick={() => factors(7, "longevityInflammation")}
              >
                <a
                  className={`component-section1 ${
                    graphName == "Longevity / Inflammation" ? `isActive` : ``
                  }`}
                >
                  <div className="imgDiv">
                    <ProgressBar
                      percentage={
                        Math.round(userData["longevityInflammation"]) || 0
                      }
                      size={"80"}
                    />
                  </div>
                  <h6 className="component-heading1">
                    Longevity / Inflammation
                  </h6>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="imgDiv1">
                  {scoreCardAllData && labels && graph ? (
                    <LineChart
                      data={graph}
                      labels={labels}
                      lineHeight={window.innerWidth < 500 ? 120 : 65}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="row factorSec">
              <div className="col-md-6">
                <div className="postivefactor postivefactor1">
                  <div className="pd1">
                    <img src={smileImg} alt="smile" />
                  </div>
                  <div className="pd2">
                    <h6 className="clr-green factorclr">Positive factors</h6>
                    <ul>
                      <li>
                        <span>{factor?.scoreCardpositveFactor?.pFactor1==null ? 'Factor 1' : factor?.scoreCardpositveFactor?.pFactor1}
                        </span>
                      </li>
                      <li>
                        <span>{factor?.scoreCardpositveFactor?.pFactor2==null ? 'Factor 2' : factor?.scoreCardpositveFactor?.pFactor2}</span>
                      </li>
                      <li>
                        <span>{factor?.scoreCardpositveFactor?.pFactor3==null ? 'Factor 3' : factor?.scoreCardpositveFactor?.pFactor3}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="postivefactor postivefactor2">
                  <div className="pd1">
                    <img className="sadFace" src={sadImg} alt="sad" />
                  </div>
                  <div className="pd2">
                    <h6 className="clr-red factorclr">Negative factors</h6>
                    <ul>
                      <li>
                        <span>{factor?.scoreCardnegeitveFactor?.nFactor1==null ? 'Factor 1' :factor?.scoreCardnegeitveFactor?.nFactor1}</span>
                      </li>
                      <li>
                        <span>{factor?.scoreCardnegeitveFactor?.nFactor2==null ? 'Factor 2' :factor?.scoreCardnegeitveFactor?.nFactor2}</span>
                      </li>
                      <li>
                        <span>{factor?.scoreCardnegeitveFactor?.nFactor3==null ? 'Factor 2' :factor?.scoreCardnegeitveFactor?.nFactor3}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ScoreChild;
